// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700';
@import 'https://fonts.googleapis.com/css2?family=Lora';
@import 'https://fonts.googleapis.com/css2?family=Poppins';

:root[mode='ios'],
:root[mode='md'] {
  --ion-font-family: 'Montserrat', sans-serif !important;

  font-family: Montserrat, sans-serif !important;
}

:root {
  /** WR **/
  --wr-color-light-blue: #225eec;
  --wr-color-blue: #285bd4;
  --wr-color-grey-blue: #406685;
  --wr-color-primary-teal: #20c4cb;
  --wr-color-white-green: #93e9be;
  --wr-color-white-blue: #c0d2f4;
  --wr-color-dark-blue: #104065;
  --wr-color-pink: #ea356d;
  --wr-color-purple: #bc33a9;
  --wr-color-yellow: #fbbd08;
  --wr-color-tan-yellow: #f9d778;
  --wr-color-orange: #f2711c;
  --wr-color-dark-green: #21ba45;
  --wr-color-light-green: #b5cc18;
  --wr-color-brown: #ad6238;
  --wr-color-grey: #171c2e;
  --wr-color-dark: #080808;
  --wr-color-grey-medium: #b6b6b8;
  --wr-color-light-grey-text: #777fa0;
  --wr-color-medium-grey: #79747e;
  --wr-color-medium-dark-grey: #4a4a4a;
  --wr-color-dark-grey: #313131;
  --wr-color-error-red: #ba2121;
  --wr-color-bg-neutral: #d8d8d8;
  --wr-color-light-gray-elements: #eaf1ff;
  --wr-color-tan-book-bg: #f6f2e5;
  --wr-color-light: #f1f1f1;
  --wr-color-white-grey: #dfdfdf;
  --wr-color-gray-placeholder: #c3cfe6;
  --wr-color-white: #fff;
  --wr-color-black: #000;

  // Buttons gradients
  --wr-color-pink-start: #ea356d;
  --wr-color-pink-end: #bc33a9;
  --wr-color-green-start: #b5cc18;
  --wr-color-green-end: #21ba45;
  --wr-color-blue-start: #20c4cb;
  --wr-color-blue-end: #285bd4;

  // Font sizes :
  --wr-size-36: 2.2rem; // 30px in figma
  --wr-size-30: 1.9rem; // 30px in figma
  --wr-size-26: 1.7rem; // 26px in figma
  --wr-size-24: 1.6rem; // 24px in figma
  --wr-size-20: 1.4rem; // 20px in figma
  --wr-size-18: 1.3rem; // 18px in figma
  --wr-size-16: 1.1rem; // 16px in figma
  --wr-size-14: 0.95rem; // 14px in figma
  --wr-size-12: 0.75rem; // 12px in figma
  --wr-size-11: 0.7rem; // 11px in figma
  --wr-size-10: 0.65rem; // 10px in figma

  // Don't use this variable directly
  // Add this class instead: app-content-max-width
  --wr-desktop-max-width: 1100px;
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-dynamic-font);
}
